export const SlugToRecipePostIdMap: Record<string, number[]> = {
  'apple-chicken-sausage': [28549],
  'apple-gouda-sausage': [],
  bacon: [26217, 28646],
  'bavette-steak': [12350],
  'beef-broth': [28586, 26838],
  'beef-burgers': [24084, 26715],
  'beef-fajita-strips': [26893, 26843],
  'beef-jerky': [24360],
  'beef-roast': [28132, 27217],
  'beef-shank': [22230, 16216],
  'beef-tenderloin': [229],
  'bison-burgers': [25804],
  bratwurst: [26619],
  'breakfast-sausage': [286, 222],
  brisket: [25469, 23377],
  'burnt-ends': [27951],
  charcuterie: [],
  'chicken-breast': [27187, 112],
  'chicken-broth': [27403, 28471],
  'chicken-drumsticks': [23638, 29274],
  'chicken-fajita-strips': [26893, 26843],
  'chicken-nuggets': [28390],
  'chicken-sausage': [28549],
  'chicken-tenders': [23755, 26613],
  'chicken-thighs': [27205, 29293],
  'chicken-wings': [23492, 29364],
  'chuck-roast': [28457, 29298],
  cod: [27396, 26192],
  'cold-cracked-lobster': [25896, 27990],
  'cooked-spare-ribs': [],
  'corned-beef': [26949, 27159],
  'crab-legs': [27008],
  'denver-steaks': [411],
  duck: [],
  'egg-bites': [],
  'filet-mignon': [23373, 27068],
  'flank-steak': [28772, 27534],
  'flat-iron-steaks': [28101, 185],
  'grilled-chicken-breast': [26843],
  'ground-beef': [23761, 29290],
  'ground-bison': [26159, 28058],
  'ground-chicken': [28804, 28804],
  'ground-italian-sausage': [27317, 23457],
  'ground-pork': [25270, 29202],
  'ground-turkey': [28361, 28122],
  halibut: [25713],
  ham: [25356, 27195],
  'hot-dogs': [28390, 27523],
  'italian-sausage': [23457, 28372],
  lamb: [27150, 25470],
  'lobster-tails': [28416, 27580],
  'london-broil': [12917, 13842],
  meatloaf: [28646],
  'ny-strip-steaks': [232, 24075],
  'pork-butt': [27209, 25366],
  'pork-chops': [24566, 345],
  'pork-country-style-ribs': [13661, 21681],
  'pork-fajita-strips': [26893, 28390],
  'pork-loin': [27214, 28498],
  'pork-ribs': [250, 27681],
  'pork-roast': [303, 28477],
  'pork-tenderloin': [25255, 28176],
  'pulled-pork': [27832],
  'ranch-steaks': [24275, 25817],
  'raw-shrimp': [28471, 28264],
  'ribeye-roast': [21, 25370],
  ribeyes: [24090, 26206],
  'rib-roast': [28394, 24700],
  sablefish: [28452, 28426],
  salmon: [28385, 28383],
  'salmon-burgers': [28971, 25515],
  scallops: [28524, 28416],
  'scotch-tender': [23381, 24679],
  'short-ribs': [22230, 13311],
  'sirloin-cap': [213, 28705],
  'smoked-beef-brisket': [25040],
  'smoked-ribs': [25040],
  'smoked-salmon': [25577],
  'steak-tips': [165, 23757],
  'stew-beef': [307, 27189],
  'stir-fry-beef': [27189, 24096],
  'thin-sliced-beef': [18919],
  'top-sirloin-steaks': [349, 24088],
  'tri-tip': [129, 26202],
  turkey: [23518, 24646],
  'veggie-burgers': [28728],
  'whole-chicken': [27185, 29295],
}
