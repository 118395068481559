import React from 'react'
import SmsUI from '~/routes/Sms/Sms.ui'
import { cleanJsonStrings } from '~/utils/unicode'
import WelcomeGuideSectionWrapper from '../WelcomeGuideSectionWrapper'
import { useStaticQuery, graphql } from 'gatsby'

const WelcomeGuideSMS = () => {
  const { optInImage } = cleanJsonStrings(
    useStaticQuery<{ optInImage: any }>(graphql`
      query {
        optInImage: file(relativePath: { eq: "sms_welcome_guide.png" }) {
          childImageSharp {
            fluid(maxWidth: 553) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )

  return (
    <WelcomeGuideSectionWrapper>
      <SmsUI
        heading="Get notified when your box ships and a link to your first box guide!"
        imgAlt="An SMS message on a mobile phone reading: ButcherBox: Your ButcherBox has just been Delivered 📦🏠"
        imgFluid={optInImage?.childImageSharp?.fluid}
        variant="section"
      />
    </WelcomeGuideSectionWrapper>
  )
}

export default WelcomeGuideSMS
