import React from 'react'
import { Card, Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './WelcomeGuideSectionHeader.css'
import { WelcomeGuideGridHeaderProps } from '~/routes/AccountWelcomeGuide/WelcomeGuide.types'

const WelcomeGuideSectionHeader = ({
  headline,
  body = '',
  eyebrow,
  number,
}: WelcomeGuideGridHeaderProps) => {
  const twoDigitNumber = number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })

  return (
    <Card boxShadow={undefined} className={Styles.GridHeader}>
      <span className={Styles.Number}>{twoDigitNumber}</span>
      <Lockup>
        <Text variant={'Eyebrow'}>{eyebrow}</Text>
        <Text variant={'H1Bold'}>{headline}</Text>
        {typeof body === 'string' ? (
          <Text variant={'H3Bold'}>{body}</Text>
        ) : (
          (body as () => JSX.Element)()
        )}
      </Lockup>
    </Card>
  )
}

export default WelcomeGuideSectionHeader
