import React from 'react'
import { TextLink, Text, List, ListItem, Lockup } from '@butcherbox/freezer'

export interface WelcomeGuideQuestion {
  id: string
  question: string
  answer: (arg: { tabIndex: undefined | -1 }) => JSX.Element
}

const AnswerOne = ({ tabIndex }) => (
  <>
    <Lockup>
      <Text variant="Body1Regular">
        We want to make sure your ButcherBox subscription works for you and your
        needs, so there are lots of options available for you to customize and
        modify. You can make changes to your box at any time before your bill
        date. If your box already billed, even if you haven’t received it yet,
        changes you make will be reflected on your next box—not the one you are
        about to receive.
      </Text>
      <Text marginBottom={16} variant="H3Bold">
        What changes can I make to my next box?
      </Text>
    </Lockup>
    <List>
      <ListItem>
        <Text variant="Body1Regular">
          {/* We can't have a p element be a decendant of another p element, thus we change this to a span */}
          <Text component="span" variant="Body1Bold">
            Change your bill date.
          </Text>{' '}
          You can move up the bill date of your next box to receive it sooner,
          or move out the bill date to receive it later. Moving out your bill
          date is a great option if you find you still have meat on hand or if
          you’re away and unable to retrieve your delivery. Your box will be
          delivered 2-5 days from the date your order bills.{' '}
          <TextLink
            href="https://support.butcherbox.com/hc/en-us/articles/115015794288-Change-my-billing-date"
            tabIndex={tabIndex}
            target="_blank"
          >
            Instructions on how to change your billing date.
          </TextLink>
        </Text>
      </ListItem>
      <ListItem>
        <Text variant="Body1Regular">
          <Text component="span" variant="Body1Bold">
            Change your box frequency
          </Text>
          You have the ability to move your subscription frequency to every 2
          weeks, 4 weeks, 6 weeks, or 8 weeks. Please note that changing your
          frequency will not change the bill date of your next box.{' '}
          <TextLink
            href="https://support.butcherbox.com/hc/en-us/articles/360009728353-Change-my-box-frequency"
            tabIndex={tabIndex}
            target="_blank"
          >
            Instructions on how to change your box frequency.
          </TextLink>
        </Text>
      </ListItem>
      <ListItem>
        <Text variant="Body1Regular">
          <Text component="span" variant="Body1Bold">
            Change your box plan and size
          </Text>{' '}
          We find many members upgrade from a Curated Box to a Custom Box in
          order to choose the cuts of meat that come in every delivery. We also
          find many members need more meat and upgrade to an XL size.
        </Text>
      </ListItem>
      <ListItem>
        <Text variant="Body1Bold">Change the meat in your box.</Text>
        <Lockup>
          <Text component="span" variant="Body1Regular">
            If you receive a Curated Box, you can change up your meat via
            add-ons or member deals.{' '}
            <TextLink
              href="https://support.butcherbox.com/hc/en-us/articles/360010013593"
              tabIndex={tabIndex}
              target="_blank"
            >
              Instructions on how to manage add-ons or member deals.
            </TextLink>
          </Text>
          <Text variant="Body1Regular">
            If you’d like to have complete control over the cuts in every order,
            we recommend our Custom Box.{' '}
            <TextLink
              href="https://support.butcherbox.com/hc/en-us/articles/360000007688-Change-what-comes-in-my-box"
              tabIndex={tabIndex}
              target="_blank"
            >
              Instructions on how to change what comes in your Custom Box.
            </TextLink>
          </Text>
        </Lockup>
      </ListItem>
      <ListItem>
        <Text variant="Body1Regular">
          <Text component="span" variant="Body1Bold">
            Change your shipping or billing information
          </Text>{' '}
          If you move or get a new credit card, it’s easy to update your
          shipping or{' '}
          <TextLink
            href="https://support.butcherbox.com/hc/en-us/articles/231185807-Change-my-billing-information"
            tabIndex={tabIndex}
            target="_blank"
          >
            billing information.
          </TextLink>
        </Text>
      </ListItem>
    </List>
  </>
)

const AnswerTwo = ({ tabIndex }) => (
  <Lockup>
    <Text variant="Body1Regular">
      We source our meat from ranchers we trust. That means working with
      companies and farmers that are dedicated to doing the right thing – never
      cutting corners, and always looking for ways to improve.
    </Text>
    <Text variant="Body1Regular">
      Our pasture-raised, 100% grass-fed & grass-finished beef comes from
      Australia. Why Australia? Thanks to the balmy climate, our cattle can roam
      free and graze year-round. Meanwhile, as the US grass-fed industry grows,
      we continue to seek domestic partnerships. Read our FAQ on grass-fed,
      grass-finished beef{' '}
      <TextLink
        href="https://support.butcherbox.com/hc/en-us/articles/360009640353"
        tabIndex={tabIndex}
        target="_blank"
      >
        here.
      </TextLink>
    </Text>
    <Text variant="Body1Regular">
      Our pork and chicken come from the US and Canada, and our special items
      such as wild-caught Alaskan sockeye salmon, lamb, and turkey all come from
      the US. Find out more about our sourcing practices and standards by
      visiting our{' '}
      <TextLink
        href="https://www.butcherbox.com/sourcing/"
        tabIndex={tabIndex}
        target="_blank"
      >
        sourcing page
      </TextLink>
      .
    </Text>
  </Lockup>
)

const AnswerThree = () => (
  <>
    <Text marginBottom={16} variant="Body1Regular">
      With the help of packaging engineering testing labs, we can figure out
      whether your box will include dry ice, and if so, how much will be needed.
      This calculated approach helps to keep your meat in the optimal
      temperature range while also reducing waste and environmental impact.
      Don’t worry if your box arrives without any dry ice!
    </Text>
  </>
)

const AnswerFour = ({ tabIndex }) => (
  <Text variant="Body1Regular">
    Please note that tracking information isn’t available until your box has
    shipped. If your box doesn’t have a tracking number yet, we may still be
    processing your order. It usually takes 1-3 days after your order is billed
    to ship your box. Once it ships, you’ll receive an email notification from{' '}
    <TextLink
      href="mailto:support@butcherbox.com"
      tabIndex={tabIndex}
      target="_blank"
    >
      support@butcherbox.com
    </TextLink>{' '}
    with tracking information, letting you know your order is on the way.
  </Text>
)

export const WELCOME_GUIDE_FAQS: WelcomeGuideQuestion[] = [
  {
    id: '1',
    question: 'What changes can I make to my next box?',
    answer: ({ tabIndex }) => <AnswerOne tabIndex={tabIndex} />,
  },
  {
    id: '2',
    question: 'Where does ButcherBox meat come from?',
    answer: ({ tabIndex }) => <AnswerTwo tabIndex={tabIndex} />,
  },
  {
    id: '3',
    question: 'How does ButcherBox keep my order cold in transit?',
    answer: () => <AnswerThree />,
  },
  {
    id: '4',
    question: 'How do I track my order?',
    answer: ({ tabIndex }) => <AnswerFour tabIndex={tabIndex} />,
  },
]
