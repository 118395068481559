import React from 'react'
import { LayoutContext } from 'design/contexts/layout'
import { Box, Button } from '@butcherbox/freezer'

/**
 * Note: This component is designed with built-in margins so that it can
 * be dropped right in as a final child in a WelcomeGuideContentGrid.
 */
export const MobileViewMoreButton = ({ onClick }) => {
  const { isMobile } = React.useContext(LayoutContext)
  if (!isMobile) return null

  return (
    <Box display={'flex'} justifyContent={'center'} marginTop={8}>
      <Button onClick={onClick} variant={'secondary'}>
        View More
      </Button>
    </Box>
  )
}
