import { LayoutContext } from 'design/contexts/layout'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'

const WelcomeGuideBackground = ({ children }) => {
  const backgroundImage = useStaticQuery(graphql`
    query {
      containerBG: contentfulImageSet(
        uniqueId: { eq: "memebership_review_background" }
      ) {
        ...ImageSet
      }
    }
  `)

  const { isMobile } = React.useContext(LayoutContext)

  return !isMobile ? (
    <ImageSetBackground imageSet={backgroundImage.containerBG}>
      {children}
    </ImageSetBackground>
  ) : (
    <>{children}</>
  )
}
export default WelcomeGuideBackground
