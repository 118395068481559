import React from 'react'
import WelcomeGuideGetToKnowUsUI from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideGetToKnowUs/WelcomeGuideGetToKnowUs.ui'
import useJustCookPosts from '~/hooks/useJustCookPosts'

const POST_IDS = [28764, 26783]

const WelcomeGuideGetToKnowUs = () => {
  const { data } = useJustCookPosts(POST_IDS)

  return <WelcomeGuideGetToKnowUsUI posts={data} />
}

export default WelcomeGuideGetToKnowUs
