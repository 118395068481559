import React from 'react'
import WelcomeGuideSectionHeader from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideContentGrid/WelcomeGuideGridHeader/WelcomeGuideSectionHeader'
import { CardGrid } from '@butcherbox/freezer'
import * as Styles from '../../WelcomeGuide.css'
import WelcomeGuideSectionWrapper from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideSectionWrapper'
import { WelcomeGuideContentGridProps } from '~/routes/AccountWelcomeGuide/WelcomeGuide.types'
import { TEST_ID } from '~/constants/cypress'

const WelcomeGuideContentGrid = ({
  children,
  columns = { mobile: 1, tablet: 3 },
  gap = 24,
  ...headerProps
}: WelcomeGuideContentGridProps) => (
  <WelcomeGuideSectionWrapper
    className={Styles.ContentGrid}
    data-testid={`${TEST_ID.WELCOME_GUIDE_SECTION}_${headerProps.number}`}
  >
    <CardGrid columns={columns} gap={gap}>
      <WelcomeGuideSectionHeader {...headerProps} />
      {children}
    </CardGrid>
  </WelcomeGuideSectionWrapper>
)

export default WelcomeGuideContentGrid
