import React from 'react'
import { UserContext } from '~/context/user'
import { graphql, useStaticQuery } from 'gatsby'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { Text, Lockup, rem } from '@butcherbox/freezer'
import * as Styles from '../WelcomeGuide.css'
import { TEST_ID } from '~/constants/cypress'

const WelcomeGuideHeader = () => {
  const user = React.useContext(UserContext)
  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "first-box-guide-hero-image" }) {
        ...ImageSet
      }
    }
  `)
  return (
    <ImageSetBackground
      breakpoints={{ mobile: '(max-width: 767px)' }}
      data-cy={TEST_ID.ACCOUNT_WELCOME_GUIDE_HEADER}
      height={rem(500)}
      imageSet={imageData.bg}
    >
      <Lockup className={Styles.HeaderText}>
        <Text color="white" component="h1" variant="DisplayOne">
          {user.firstName}'s <br />
          First Box Guide
        </Text>
      </Lockup>
    </ImageSetBackground>
  )
}

export default WelcomeGuideHeader
