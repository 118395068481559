import React from 'react'
import {
  ButtonLink,
  ButtonLinkProps,
  CardAction,
  IconNewWindow,
} from '@butcherbox/freezer'

const ReadMoreCTA = ({
  recipeLink,
  ...buttonLinkProps
}: { recipeLink: string } & ButtonLinkProps) => {
  return (
    <CardAction textAlign="center">
      <ButtonLink
        data-what="recipe_card"
        href={recipeLink}
        size="fluid"
        target="_blank"
        variant="text"
        {...buttonLinkProps}
      >
        Read More
        <IconNewWindow
          customColor={{ base: 'spicedCrimson' }}
          display={'inlineBlock'}
          marginBottom={4}
          marginLeft={4}
          size={'text'}
        />
      </ButtonLink>
    </CardAction>
  )
}
export default ReadMoreCTA
