import { Box, Text } from '@butcherbox/freezer'
import { LayoutContext } from 'design/contexts/layout'
import React from 'react'
import { Order } from '~/bb-api/schemata'
import { useSelectVideosByOrder } from '~/experiments/stay-702-wg-tips-techniques/useSelectVideosByOrder'
import useViewMore from '~/hooks/useViewMore'
import { MobileViewMoreButton } from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideContentGrid/MobileViewMoreButton'
import WelcomeGuideContentGrid from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideContentGrid/WelcomeGuideContentGrid'

export const WelcomeGuideTipsAndTechniques = ({ order }: { order: Order }) => {
  const { isMobile } = React.useContext(LayoutContext)
  const allVideos = useSelectVideosByOrder(order)

  const { showViewMoreButton, viewMore, items: videos } = useViewMore(
    allVideos,
    {
      initialLength: !isMobile ? allVideos.length : 2,
    }
  )

  return (
    <WelcomeGuideContentGrid
      columns={{ mobile: 1, tablet: 2 }}
      eyebrow="Build Skills"
      headline="Trusted tips & techniques"
      number={3}
    >
      {videos.map(({ src, title }, i) => (
        <Box key={i}>
          <iframe
            allow="autoplay; fullscreen"
            allowFullScreen
            data-chromatic="ignore"
            frameBorder="0"
            src={src}
            style={{
              aspectRatio: '16/9',
              width: '100%',
            }}
            title={title}
          />
          <Text variant="H3Bold">{title}</Text>
        </Box>
      ))}
      {showViewMoreButton && <MobileViewMoreButton onClick={viewMore} />}
    </WelcomeGuideContentGrid>
  )
}

export default WelcomeGuideTipsAndTechniques
