import { Box, Lockup, Text } from '@butcherbox/freezer'
import dayjs from 'dayjs'
import React from 'react'
import { Order, ShipZone } from '~/bb-api/schemata'
import createDeliveryWindow from '~/components/Calendar/deliveryWindow'
import WelcomeGuideFaqList from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideShipmentAndFaqs/WelcomeGuideFaqList'
import * as HeaderStyles from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideContentGrid/WelcomeGuideGridHeader/WelcomeGuideSectionHeader.css'
import * as Styles from './WelcomeGuideShipmentAndFaqs.css'

const WelcomeGuideShipmentAndFaqsUI = ({
  order,
  shipZone,
}: {
  order: Order
  shipZone: ShipZone
}) => (
  <Box className={Styles.SectionWrapper}>
    <span className={HeaderStyles.Number}>01</span>
    <Lockup paddingTop={8} textAlign="center">
      <Text variant="Subhead1"> Get ready</Text>

      <ShipmentWeek order={order} shipZone={shipZone} />
    </Lockup>

    <Box className={Styles.Video}>
      <iframe
        allow="autoplay; fullscreen"
        allowFullScreen
        frameBorder="0"
        src="https://player.vimeo.com/video/812153799?h=d7604b6548%22"
        style={{
          position: 'relative',
          aspectRatio: '16 / 9',
          width: '100%',
        }}
        title="Video: ButcherBox Unboxing with Chef Yankel"
      ></iframe>
    </Box>
    <Text textAlign="center" variant="H3Bold">
      Watch our complete guide to unboxing and storage. Questions? We’re here to
      help.
    </Text>
    <WelcomeGuideFaqList />
  </Box>
)

const ShipmentWeek = ({
  order,
  shipZone,
}: {
  order: Order
  shipZone: ShipZone
}) => {
  let message = ''
  let date = ''

  switch (order.trackingInformation.status) {
    case 'in_transit':
      message = 'Your box shipped'
      date = dayjs(order.trackingInformation.shipmentDate).format('MMM D')
      break
    case 'delivered':
      message = 'Your box was delivered on'
      date = dayjs(order.trackingInformation.deliveryDate).format('MMM D')
      break
    case 'processing':
      message = 'Your box arrives'
      const deliveryWindow = createDeliveryWindow(order.datePaid, shipZone)

      const deliveryWindowEnd = deliveryWindow[deliveryWindow.length - 1]
      const deliveryWindowEndFormatted =
        deliveryWindow[0].month() === deliveryWindowEnd.month()
          ? deliveryWindowEnd.format('D')
          : deliveryWindowEnd.format('MMM D')

      date = `${deliveryWindow[0].format(
        'MMM D'
      )}-${deliveryWindowEndFormatted}`
      break
    default:
      break
  }

  return (
    <Box className={Styles.ShipmentWindow}>
      <Text variant="H1Bold">{message}&nbsp;</Text>
      <Text color="spicedCrimson" variant="H1Bold">
        {date}
      </Text>
    </Box>
  )
}

export default WelcomeGuideShipmentAndFaqsUI
