import { Order } from '~/bb-api/schemata'
import { consolidateOrderItems } from '~/utils/consolidateOrderItems'
import { getTagInfoForSku } from "~/data/justcook-tags-data";

type VideoData = { title: string; src: string }

const isVideo = (
  item: VideoData | Record<string, VideoData>
): item is VideoData => !!item.title

export const videos: Array<VideoData | Record<string, VideoData>> = [
  {
    title: 'How to Safely Store & Defrost Meat ',
    src: 'https://player.vimeo.com/video/812154077?h=125d39d26c%22',
  },
  {
    true: {
      title: 'Cooking Ground Beef from Frozen',
      src: 'https://player.vimeo.com/video/812154145?h=35b4272efc',
    },
    false: {
      title: 'Dry Rubs, Marinades and Brines',
      src: 'https://player.vimeo.com/video/803131689?h=3bbe3f949c%22',
    },
  },
  {
    title: 'Searing and Basting',
    src: 'https://player.vimeo.com/video/803138564?h=125d39d26c%22',
  },
]

export const useSelectVideosByOrder = (order: Order) => {
  const orderItems = consolidateOrderItems(order)
  const hasGroundBeef = !!orderItems.find((item) => {
    const tagInfoForSku = getTagInfoForSku(Number(item.sku));
    return tagInfoForSku?.slug === 'ground-beef'
  })

  return videos.map((item) => (isVideo(item) ? item : item[`${hasGroundBeef}`]))
}
