import React from 'react'
import WelcomeGuideContentGrid from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideContentGrid/WelcomeGuideContentGrid'
import RecipeCard from '~/components/RecipeCard/RecipeCard'
import ReadMoreCTA from '~/components/RecipeCard/ReadMoreCTA'
import { JustCookRecipe } from '~/bb-api/schemata'

const WelcomeGuideGetToKnowUsUI = ({ posts }: { posts: JustCookRecipe[] }) => (
  <WelcomeGuideContentGrid
    eyebrow={'Support Sustainability'}
    headline={'Get to know us'}
    number={4}
  >
    {posts?.map((post) => (
      <RecipeCard
        cardWidth={'fluid'}
        cta={() => (
          <ReadMoreCTA
            data-what="welcome_guide_read_more"
            recipeLink={post.link}
          />
        )}
        key={post.id}
        recipe={post}
      />
    ))}
  </WelcomeGuideContentGrid>
)

export default WelcomeGuideGetToKnowUsUI
