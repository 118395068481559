import React from 'react'
import { Box, BoxProps } from '@butcherbox/freezer'

const WelcomeGuideSectionWrapper = ({ children, ...boxProps }: BoxProps) => {
  return (
    <Box background={'white'} w={'100%'} {...boxProps}>
      {children}
    </Box>
  )
}

export default WelcomeGuideSectionWrapper
