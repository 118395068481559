import { LayoutContext } from 'design/contexts/layout'
import { default as React } from 'react'
import { BoxItem, Order } from '~/bb-api/schemata'
import { SlugToRecipePostIdMap } from '~/data/justcook-recipe-data'
import { getTagInfoForSku } from '~/data/justcook-tags-data'
import useJustCookPosts from '~/hooks/useJustCookPosts'
import useViewMore from '~/hooks/useViewMore'
import { consolidateOrderItems } from '~/utils/consolidateOrderItems'
import WelcomeGuideRecipesUI from './WelcomeGuideRecipes.ui'

type UpcomingOrderCutPostIds = Array<number[]>

// this should always have at least 5 items (see while loop):
export const DEFAULT_RECIPES = [27456, 26338, 29202, 28620, 26502]

export const translateOrderItemsToPostIds = (
  orderItems: BoxItem[]
): Array<number[]> => {
  return orderItems
    .map((item) => parseInt(item.sku))
    .map((sku) => getTagInfoForSku(sku)?.slug ?? '')
    .map((slug) => SlugToRecipePostIdMap[slug] ?? [])
}

export const createRecipesArray = (
  cutsPostIdsArray: UpcomingOrderCutPostIds
): {
  recipePostIds: number[]
  areDefaultsIncluded: boolean
  allRecipesDefault: boolean
} => {
  const recipes = []
  let currentCutIdx = 0
  let currentRecipeIdx = 0
  let areDefaultsIncluded = false

  if (!cutsPostIdsArray.length)
    return {
      recipePostIds: DEFAULT_RECIPES,
      areDefaultsIncluded: true,
      allRecipesDefault: true,
    }

  const longestCutArrayLength = Math.max(
    ...cutsPostIdsArray.map((cut) => cut.length)
  )

  while (recipes.length < 5) {
    const currentPostId = cutsPostIdsArray[currentCutIdx][currentRecipeIdx]
    if (currentPostId && !recipes.includes(currentPostId)) {
      recipes.push(currentPostId)
    }
    // if we've reached the end of the input array `cutsPostIdsArray`:
    if (currentCutIdx === cutsPostIdsArray.length - 1) {
      // if we are on the last index of the last sub array, `cutsPostIdsArray[lastIndex][lastIndex]`, and have less than 5 recipes then add the amount of necessary default recipes to have a total of 5:
      if (currentRecipeIdx >= longestCutArrayLength - 1) {
        recipes.push(...DEFAULT_RECIPES.slice(0, 5 - recipes.length))
        areDefaultsIncluded = true
      }
      // otherwise increment recipe index to check next recipe of each sub array for additional post Ids, i.e `cutsPostIdsArray[lastIndex][0]` -> `cutsPostIdsArray[0][1]`:
      currentCutIdx = 0
      currentRecipeIdx++
    } else {
      // go to the next sub array if we haven't reached the end of the `cutsPostIdsArray`:
      currentCutIdx++
    }
  }

  return {
    recipePostIds: recipes,
    areDefaultsIncluded,
    allRecipesDefault: false,
  }
}

const WelcomeGuideRecipes = ({ order }: { order: Order }) => {
  const {
    recipePostIds,
    areDefaultsIncluded,
    allRecipesDefault,
  } = React.useMemo(() => {
    const orderItems = consolidateOrderItems(order)
    const cutsPostIdsArray = translateOrderItemsToPostIds(orderItems)
    return createRecipesArray(cutsPostIdsArray)
  }, [order])

  const { data: recipes, isLoading } = useJustCookPosts(recipePostIds)

  const { isMobile } = React.useContext(LayoutContext)
  const { showViewMoreButton, items, viewMore } = useViewMore(recipes, {
    initialLength: isMobile ? 2 : recipes?.length,
  })

  return (
    <WelcomeGuideRecipesUI
      allRecipesDefault={allRecipesDefault}
      areDefaultsIncluded={areDefaultsIncluded}
      isLoading={isLoading}
      recipes={items}
      showViewMoreButton={showViewMoreButton}
      viewMore={viewMore}
    />
  )
}

export default WelcomeGuideRecipes
