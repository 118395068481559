import { Box } from '@butcherbox/freezer'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { Box as OrderBox, Order } from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import useOrders from '~/hooks/useOrders'
import * as Styles from '../WelcomeGuide.css'
import WelcomeGuideBackGround from './WelcomeGuideBackground'
import { WelcomeGuideFeedbackFrom } from './WelcomeGuideFeedbackFrom/WelcomeGuideFeedbackFrom'
import GetToKnowUs from './WelcomeGuideGetToKnowUs/WelcomeGuideGetToKnowUs'
import WelcomeGuideRecipes from './WelcomeGuideRecipes/WelcomeGuideRecipes'
import WelcomeGuideSectionWrapper from './WelcomeGuideSectionWrapper'
import WelcomeGuideShipmentAndFaqs from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideShipmentAndFaqs/WelcomeGuideShipmentAndFaqs'
import WelcomeGuideSMS from './WelcomeGuideSMS/WelcomeGuideSMS'
import TipsAndTechniques from './WelcomeGuideTipsAndTechniques/WelcomeGuideTipsAndTechniques'

export const getFirstOrderItems = ({
  orders,
  subscriptionBox,
  isLoading,
}: {
  orders: Order[]
  subscriptionBox: OrderBox
  isLoading: boolean
}): Order => {
  if (isLoading) return null

  const firstOrder = orders[orders.length - 1]

  //if the first order has no box items then use the current subscription's box items
  const boxItems =
    firstOrder.box.items.length !== 0
      ? firstOrder.box.items
      : subscriptionBox.items

  return {
    ...firstOrder,
    box: {
      ...firstOrder.box,
      items: boxItems,
    },
  }
}

const WelcomeGuideContent = () => {
  const { data: orders, isLoading: isFirstOrderLoading } = useOrders()
  const { subscription } = React.useContext(SubscriptionContext)

  const firstOrder = getFirstOrderItems({
    orders,
    subscriptionBox: subscription.box,
    isLoading: isFirstOrderLoading,
  })

  return (
    <WelcomeGuideBackGround>
      <Box className={Styles.Container}>
        {isFirstOrderLoading ? (
          <LoadingSection />
        ) : (
          <WelcomeGuideShipmentAndFaqs order={firstOrder} />
        )}
        {isFirstOrderLoading ? (
          <LoadingSection />
        ) : (
          <WelcomeGuideRecipes order={firstOrder} />
        )}
        {isFirstOrderLoading ? (
          <LoadingSection />
        ) : (
          <TipsAndTechniques order={firstOrder} />
        )}
        <GetToKnowUs />
        <WelcomeGuideSMS />
        <WelcomeGuideFeedbackFrom />
      </Box>
    </WelcomeGuideBackGround>
  )
}

const LoadingSection = () => {
  return (
    <WelcomeGuideSectionWrapper>
      <LoadingSpinner size={'regular'} />
    </WelcomeGuideSectionWrapper>
  )
}

export default WelcomeGuideContent
