import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonLink,
} from '@butcherbox/freezer'
import React from 'react'
import {
  WELCOME_GUIDE_FAQS,
  WelcomeGuideQuestion,
} from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideShipmentAndFaqs/WelcomeGuideFaqList.fixtures'
import { QuestionsContainer } from '~/routes/AccountWelcomeGuide/WelcomeGuideContent/WelcomeGuideShipmentAndFaqs/WelcomeGuideShipmentAndFaqs.css'

export const Question = ({
  id,
  question,
  answer: Answer,
}: WelcomeGuideQuestion) => {
  const [tabIndex, setTabIndex] = React.useState<undefined | -1>(-1)
  const onChangeHandler = (isOpen: boolean) => {
    setTabIndex(isOpen ? undefined : -1)
  }
  return (
    <Accordion data-what={`wg-faq`} id={id} onChange={onChangeHandler}>
      <AccordionSummary>{question}</AccordionSummary>
      <AccordionDetails>
        <Answer tabIndex={tabIndex} />
      </AccordionDetails>
    </Accordion>
  )
}
const WelcomeGuideFaqList = () => (
  <Box className={QuestionsContainer}>
    {WELCOME_GUIDE_FAQS.map(({ question, answer, id }) => (
      <Question answer={answer} id={id} key={id} question={question} />
    ))}
    <ButtonLink
      href="https://www.butcherbox.com/faqs"
      marginTop={32}
      target="_blank"
    >
      View All FAQs
    </ButtonLink>
  </Box>
)

export default WelcomeGuideFaqList
