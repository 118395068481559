export const CARD_WIDTH = 320
export const GAP_WIDTH = 24
export const MAX_WIDTH = CARD_WIDTH * 3 + GAP_WIDTH * 2 + 20
export const SKU_RANKING = [
  '971350',
  '971411',
  '971622',
  '971360',
  '971314',
  '971439',
  '971323',
  '971801',
  '971624',
  '971625',
]
export const EXCLUDED_MEAT_GROUPS = ['dry', 'pet_food']
