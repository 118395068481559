import { useJustCookQuery } from '~/hooks/useJustCookQuery'

type HookConfig = {
  page?: number
}

const useJustCookPosts = (
  postIds: number[],
  { page }: HookConfig = { page: 1 }
) => {
  const { data, ...queryResult } = useJustCookQuery({
    searchUrl: `include=${postIds}`,
    orderBy: 'include',
    perPage: Math.min(100, postIds.length),
    page,
    enabled: !!postIds,
  })

  return { data: data?.posts, ...queryResult }
}

export default useJustCookPosts
