import React from 'react'
import { Order } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import WelcomeGuideShipmentAndFaqsUI from './WelcomeGuideShipmentAndFaqsUI'

const WelcomeGuideShipmentAndFaqs = ({ order }: { order: Order }) => {
  const { shipZone } = React.useContext(UserContext)

  return <WelcomeGuideShipmentAndFaqsUI order={order} shipZone={shipZone} />
}

export default WelcomeGuideShipmentAndFaqs
