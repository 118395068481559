import { Text, TextLink } from '@butcherbox/freezer'
import React from 'react'
import FeedbackForm from '~/components/FeedbackForm/FeedbackForm'
import * as Styles from '../../WelcomeGuide.css'
import WelcomeGuideSectionWrapper from '../WelcomeGuideSectionWrapper'

export const FooterContent = (
  <Text variant="Body2Regular">
    For any further questions, please contact our support team at <br />
    <TextLink
      href="mailto:support@butcherbox.com"
      target="_self"
      theme="slate"
      underline={true}
      variant="Body2Regular"
    >
      support@butcherbox.com
    </TextLink>
  </Text>
)

export const WelcomeGuideFeedbackFrom: React.FC = () => {
  return (
    <WelcomeGuideSectionWrapper className={Styles.ContentGrid}>
      <FeedbackForm
        footerContent={FooterContent}
        formId="welcome-guide-feedback-form"
      />
    </WelcomeGuideSectionWrapper>
  )
}
