import merge from 'lodash.merge'
import { BoxItem, Order, UpcomingOrder } from '~/bb-api/schemata'
import { SKU_EXCLUSIONS } from '~/constants/skuExclusions'
import {
  EXCLUDED_MEAT_GROUPS,
  SKU_RANKING,
} from '~/routes/AccountWhatsForDinner/WhatsForDinner/WhatsForDinnerProductList/WhatsForDinnerProductList.constants'
import { BoxItemRank } from '~/routes/AccountWhatsForDinner/WhatsForDinner/WhatsForDinnerProductList/WhatsForDinnerProductList.types'
import { skuMap } from '~/utils/combineskus'

const removeExcludedSkus = (boxItems: BoxItem[]) => {
  return boxItems.filter(
    (product: BoxItem) =>
      !SKU_EXCLUSIONS.includes(product.sku) &&
      !EXCLUDED_MEAT_GROUPS.includes(product.meatGroup)
  )
}

const getGroupedItems = (boxItems: BoxItem[]) => {
  return boxItems.reduce((acc, current: any) => {
    if (current.hasOwnProperty('groupedItems')) {
      return [
        ...acc,
        ...current.groupedItems.map((item: BoxItem) => {
          return { ...item, quantity: item.quantity * current.quantity }
        }),
      ]
    } else {
      return [...acc, current]
    }
  }, [])
}

const findAndConvertLegacySkus = (boxItems: BoxItemRank[]) => {
  return boxItems.map((item, i) => {
    if (skuMap[item.sku]) {
      item = merge(item, skuMap[item.sku])
      item.sku = item.sku.toString()
    }
    const rankedSku = SKU_RANKING.indexOf(item.sku)
    if (rankedSku !== -1) {
      item.rank = rankedSku
    } else {
      item.rank = SKU_RANKING.length + i
    }

    return item
  })
}

const combineLikeItemsAndIncreaseQuantity = (boxItems: BoxItemRank[]) => {
  return boxItems.reduce((acc, current) => {
    const index = acc.findIndex((item: BoxItemRank) => item.sku === current.sku)
    if (index === -1) {
      return [...acc, current]
    } else {
      return Object.assign([], acc, {
        [index]: {
          ...acc[index],
          quantity: acc[index].quantity + current.quantity,
        },
      })
    }
  }, [])
}

const sortBoxItemsByRank = (boxItems: BoxItemRank[]) => {
  return boxItems.sort((a: BoxItemRank, b: BoxItemRank) => a.rank - b.rank)
}

const mutate3PackBacon = (item) => {
  const fieldsToMutate = {
    sku: '971726',
    quantity: item.quantity * 3,
    packSize: '1 x 10 oz pack',
    description: 'ButcherBox Bacon',
    image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
  }

  if (item.hasOwnProperty('groupedItems')) {
    return {
      ...item,
      ...fieldsToMutate,
      groupedItems: item.groupedItems.map((gpi: BoxItem) => {
        return { ...gpi, ...fieldsToMutate, quantity: gpi.quantity }
      }),
    }
  } else {
    return {
      ...item,
      ...fieldsToMutate,
    }
  }
}

export function consolidateOrderItems(
  order: Order | UpcomingOrder = {} as Order | UpcomingOrder
): BoxItemRank[] {
  const box = 'subscription' in order ? order.subscription?.box : order.box
  if (!box) return []
  const boxItemData = [
    ...box?.items,
    ...box?.offers,
    ...box?.addons,
    ...order?.invoiceItems,
    ...order?.extras,
  ]

  const boxItemsConsolidated = boxItemData.map((item) =>
    //Check if order has 3 - pack bacon sku(974022), if it does replace it with regular
    //bacon
    item.sku === '974022' ? mutate3PackBacon(item) : item
  )
  const boxItemsWithoutExcludedSkus = removeExcludedSkus(boxItemsConsolidated)
  const boxItemsWithGroupedItems = getGroupedItems(boxItemsWithoutExcludedSkus)
  // we only need to perform the logic below for recently shipped boxes, not next boxes:
  if (!('subscription' in order)) {
    const boxItemsWithConvertedLegacySkus = findAndConvertLegacySkus(
      boxItemsWithGroupedItems
    )
    const boxItemsWithCombinedLikeItems = combineLikeItemsAndIncreaseQuantity(
      boxItemsWithConvertedLegacySkus
    )
    const boxItemsSortedByRank = sortBoxItemsByRank(
      boxItemsWithCombinedLikeItems
    )

    return boxItemsSortedByRank
  }

  return boxItemsWithGroupedItems
}
