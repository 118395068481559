import type { RouteComponentProps } from '@reach/router'
import React from 'react'
import WelcomeGuideContent from './WelcomeGuideContent/WelcomeGuideContent'
import WelcomeGuideHeader from './WelcomeGuideHeader/WelcomeGuideHeader'

const WelcomeGuide: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <WelcomeGuideHeader />
      <WelcomeGuideContent />
    </>
  )
}

export default WelcomeGuide
