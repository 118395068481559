import { useState } from 'react'

type HookConfig = {
  initialLength?: number
}
const defaultConfig = { initialLength: 2 }

/**
 * This hook returns:
 * - the items of the array to display, depending on whether we are
 * currently "viewing more."
 * - the function to turn on "viewing more"
 * - whether we are viewing more, and whether to show the "view more" button (which are opposites of each other)
 *
 * **NOTE:**
 *
 * If you provide an `initialLength` that is equal to the length of the array,
 * the hook will treat this as "already viewing more." This allows you to quite
 * easily conditionalize the use of the View More button and functionality.
 *
 * @example
 * const { isMobile } = getScreenSize()
 * const { items, viewMore, showViewMoreButton } =
 *   useViewMore(allItems, { initialLength: isMobile ? 2 : allItems.length })
 *
 * return <>
 *     // `items` length controlled by the hook
 *     <ItemsList items={items} />
 *
 *     // if we're not on mobile, the hook will always return showViewMoreButton = false,
 *     // so no extra mobile logic is needed for rendering
 *     { showViewMoreButton && <MobileViewMoreButton onClick={viewMore} />}
 *   </>
 */
const useViewMore = (
  allItems: any[] = [],
  { initialLength }: HookConfig = defaultConfig
) => {
  const alreadyShowingMore = initialLength === allItems.length
  const [isShowingMore, setIsShowingMore] = useState(alreadyShowingMore)

  const displayLength = isShowingMore ? allItems.length : initialLength

  return {
    items: allItems.slice(0, displayLength),
    viewMore: () => setIsShowingMore(true),
    isShowingMore,
    showViewMoreButton: !isShowingMore,
  }
}

export default useViewMore
