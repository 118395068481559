import React from 'react'
import WelcomeGuideContentGrid from '../WelcomeGuideContentGrid/WelcomeGuideContentGrid'
import { JustCookRecipe } from '~/bb-api/schemata'
import RecipeList from '~/components/RecipeList/RecipeList'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { MobileViewMoreButton } from '../WelcomeGuideContentGrid/MobileViewMoreButton'
import { Text, TextLink } from '@butcherbox/freezer'

interface WelcomeGuideRecipesUIProps {
  recipes: JustCookRecipe[]
  isLoading: boolean
  showViewMoreButton: boolean
  viewMore: () => void
  areDefaultsIncluded: boolean
  allRecipesDefault: boolean
}

const WelcomeGuideCopy = ({
  areDefaultsIncluded,
  allRecipesDefault,
}: {
  areDefaultsIncluded: boolean
  allRecipesDefault: boolean
}) => (
  <>
    <Text variant="H3Bold">
      {allRecipesDefault
        ? 'Check out these popular recipes to give you a flavor of our cooking content.'
        : 'Looking for even more inspiration?'}
      <TextLink
        data-what="browse_recipes_link"
        display={{ mobile: 'block' }}
        href="/account/recipes"
        underline
        variant="H3Bold"
      >
        Click here to browse all Recipes.
      </TextLink>
    </Text>
    {areDefaultsIncluded && !allRecipesDefault && (
      <Text variant="Body1Regular">
        We have included a popular recipe for a cut not in your box to give you
        a flavor of our cooking content.
      </Text>
    )}
  </>
)

const WelcomeGuideRecipesUI = ({
  recipes,
  isLoading,
  showViewMoreButton,
  viewMore,
  areDefaultsIncluded,
  allRecipesDefault,
}: WelcomeGuideRecipesUIProps) => {
  return (
    <WelcomeGuideContentGrid
      body={() => (
        <WelcomeGuideCopy
          allRecipesDefault={allRecipesDefault}
          areDefaultsIncluded={areDefaultsIncluded}
        />
      )}
      eyebrow={'get inspired'}
      headline={'Recipes curated for your first box'}
      number={2}
    >
      {isLoading ? (
        <div style={{ gridColumn: 'span 2' }}>
          <LoadingSpinner size={'regular'} />
        </div>
      ) : (
        <RecipeList cardWidth="fluid" recipes={recipes} />
      )}
      {showViewMoreButton && (
        <MobileViewMoreButton data-what="view_more_button" onClick={viewMore} />
      )}
    </WelcomeGuideContentGrid>
  )
}

export default WelcomeGuideRecipesUI
